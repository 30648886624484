<template>
  <div class="clientes">
    <h1 class="titulo-card fs-4">Provisionamento</h1>
    <div class="form-group card-principal">
      <div class="row">

        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
          <div class="card text-white bg-primary h-100">
            <div class="card-header">Aguardando autorização</div>
            <div class="card-body text-end">
              <h5 class="card-title">
                <span class="icone float-start">
                  <font-awesome-icon :icon="['fas', 'user-plus']" size="2x" fixed-width class="me-1"/>
                </span>
                <font-awesome-icon v-if="(carregandoContadores)" :icon="['fas', 'spinner']" spin fixed-width class="me-1"/>
                <span v-if="(!carregandoContadores)" v-on:click="navegaParaAguardando()" class="cursor-pointer">
                  {{contadores.onusDiscovery}}
                </span>
              </h5>
              <p class="card-text"></p>
            </div>
            <div class="card-footer bg-white">
              <small v-on:click="navegaParaAguardando()" class="cursor-pointer text-dark float-end">
                <font-awesome-icon :icon="['fas', 'eye']" fixed-width class="me-1"/>
                Visualizar
              </small>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
          <div class="card text-white bg-success h-100">
            <div class="card-header">Autorizadas</div>
            <div class="card-body text-end">
              <h5 class="card-title">
                <span class="icone float-start">
                  <font-awesome-icon :icon="['fas', 'plug']" size="2x" fixed-width class="me-1"/>
                </span>
                <font-awesome-icon v-if="(carregandoContadores)" :icon="['fas', 'spinner']" spin fixed-width class="me-1"/>
                <span v-if="(!carregandoContadores)" v-on:click="navegaParaAguardando()" class="cursor-pointer">
                  {{contadores.registeredOnus}}
                </span>
              </h5>
              <p class="card-text">online - {{contadores.onusOnline}}</p>
            </div>
            <div class="card-footer bg-white">
              <small v-on:click="navegaParaOnus()" class="cursor-pointer text-dark float-end">
                <font-awesome-icon :icon="['fas', 'eye']" fixed-width class="me-1"/>
                Visualizar
              </small>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
          <div class="card text-white bg-secondary h-100 card-disabled">
            <div class="card-header">Offline</div>
            <div class="card-body text-end">
              <h5 class="card-title">
                <span class="icone float-start">
                  <font-awesome-icon :icon="['far', 'times-circle']" size="2x" fixed-width class="me-1"/>
                </span>
                <span v-if="(!carregandoContadores)" v-on:click="navegaParaOffline()" class="cursor-pointer">
                  {{contadores.registeredOnus - contadores.onusOnline}}
                </span>
              </h5>
              <p class="card-text">desligado - 0 / perdeu sinal - 0</p>
            </div>
            <div class="card-footer bg-white">
              <small v-on:click="navegaParaOffline()" class="cursor-pointer text-dark float-end">
                <font-awesome-icon :icon="['fas', 'eye']" fixed-width class="me-1"/>
                Visualizar
              </small>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
          <div class="card bg-warning h-100 card-disabled">
            <div class="card-header">Sinal baixo</div>
            <div class="card-body text-end">
              <h5 class="card-title">
                <span class="icone float-start">
                  <!--<font-awesome-icon :icon="['fas', 'wave-square']" size="2x" fixed-width class="me-1"/>-->
                  <font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="2x" fixed-width class="me-1"/>
                </span>
                <font-awesome-icon v-if="(carregandoContadores)" :icon="['fas', 'spinner']" spin fixed-width class="me-1"/>
                <span v-if="(!carregandoContadores)" v-on:click="navegaParaSinalBaixo()" class="cursor-pointer">
                  {{contadores.signalAlert + contadores.signalCritic}}
                </span>
              </h5>
              <p class="card-text">alerta - {{contadores.signalAlert}} / críticos - {{contadores.signalCritic}}</p>
            </div>
            <div class="card-footer bg-white">
              <small v-on:click="navegaParaSinalBaixo()" class="cursor-pointer text-dark float-end">
                <font-awesome-icon :icon="['fas', 'eye']" fixed-width class="me-1"/>
                Visualizar
              </small>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api'

export default {
  name: 'ProvisionamentoOnus',

  data() {
    return {
      carregandoContadores: true,
      intervaloAtualizaContadores: undefined,
      contadores: {
        onusDiscovery: 0,
        registeredOnus: 0,
        onusOnline: 0,
        signalAlert: 0,
        signalCritic: 0,
      }
    }
  },

  methods: {
    carregaContadores() {
      this.carregandoContadores = false;
      const apiUrl = `/dashboards/olt-provision`;

      api.get(apiUrl).then(res => {
        this.carregandoContadores = false;
        this.contadores = res.data;
      }).catch(() => {
        //
      });
    },

    navegaParaAguardando() {
      this.$router.push({ path: `/provisionamento-onus/aguardando` });
    },

    navegaParaOnus() {
      this.$router.push({ path: `/provisionamento-onus/onus` });
    },

    navegaParaOffline() {
      // this.$router.push({ path: `/provisionamento-onus/onus` });
    },

    navegaParaSinalBaixo() {
      // this.$router.push({ path: `/provisionamento-onus/onus` });
    },
  },

  created() {
    this.carregaContadores();
    this.intervaloAtualizaContadores = setInterval(this.carregaContadores, 3000);
  },

  destroyed() {
    clearInterval(this.intervaloAtualizaContadores);
  },
}
</script>

<style>
.card-disabled {
  opacity: 0.5;
}
</style>